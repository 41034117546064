<template>
    <div>
        <styled-slideout
            :value="value"
            :width="875"
            @input="$emit('input', $event)">
            <loader v-if="loading" />
            <div
                v-else
                class="exports">
                <div class="exports-title">
                    Catalog Exports
                </div>
                <div
                    v-if="displayedExports.length"
                    class="exports-desc">
                    Use the list of URLs below to reference available channel catalog exports.
                    This can be helpful if you need to create another catalog on each platform or are trying to use the
                    feed for alternate purposes.
                </div>
                <div
                    v-if="displayedExports.length && !hasInventory"
                    class="no-inventory-notice">
                    Exports have been configured but there is current no active inventory on this account.
                    <span @click.prevent="$store.dispatch('createOnboardingSupportTicket')">Please contact support for assistance</span>
                    if you believe there is a problem with your inventory.
                </div>
                <div
                    v-else-if="!displayedExports.length"
                    class="no-inventory-notice">
                    Catalog exports are not available until inventory is fully configured and an inventory-based
                    advertising product has been successfully onboarded.
                </div>
                <div
                    v-if="displayedExports.length"
                    class="exports-list">
                    <div class="exports-header">
                        <div class="exports-block">
                            <div class="exports-left">
                                <div class="exports-subtitle">
                                    Channel
                                </div>
                            </div>
                            <div class="exports-right">
                                <div class="exports-subtitle">
                                    Export URL
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="exports-body">
                        <div
                            v-for="(channel, index) in displayedExports"
                            :key="index"
                            class="exports-block">
                            <div class="exports-left">
                                <img
                                    class="exports-logo"
                                    :src="`/img/platforms/${channel.channel}.svg`">
                                <span class="exports-bold">{{ capitalizeWord(channel.name ? channel.name : channel.channel) }}</span>
                            </div>
                            <div class="exports-right">
                                <div class="exports-link">
                                    <a
                                        :href="channel.url"
                                        target="_blank">
                                        {{ channel.url }}
                                    </a>
                                </div>
                                <div class="exports-copy">
                                    <action-button
                                        v-clipboard:copy="channel.url"
                                        v-clipboard:success="onCopy"
                                        class="ml-2"
                                        size="18"
                                        icon="copy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="displayedExports.length"
                    class="marketplace-note">
                    Note that Marketplace catalogs (if in use) cannot be viewed as they are connected directly to each
                    platform through a proprietary exchange
                </div>
            </div>
        </styled-slideout>
    </div>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import ActionButton from '@/components/globals/ActionButton';
import Loader from '@/components/globals/Loader';
import { mapState } from 'vuex';
import { capitalizeWord } from '@/helpers/capitalizeWord';
import { PLATFORM_MICROSOFT, PLATFORM_FACEBOOK, FEEDS_BASE_URL } from '@/helpers/globals';

export default {
    components: {
        StyledSlideout,
        ActionButton,
        Loader,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        hasInventory: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        catalogExports: {
            type: Array,
            requred: true,
        },
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            activatedChannels: state => state.settings.activatedChannels,
        }),
    },
    watch: {
        loading() {
            if (!this.loading) this.setExports();
        },
    },
    data() {
        return {
            capitalizeWord,
            displayedExports: [],
        };
    },
    created() {
        this.setExports();
    },
    methods: {
        setExports() {
            this.displayedExports = [];
            const uniqueChannels = new Set();
            this.activatedChannels.forEach(channel => {
                const channelName = channel.substring(0, channel.indexOf('_'));
                uniqueChannels.add(channelName);
            });
            const activeChannels = Array.from(uniqueChannels);
            const amazonIsAvailable = this.catalogExports.find(e => e.remote_file_type.name === 'Amazon Auto');

            if (amazonIsAvailable) {
                activeChannels.push('amazon');
            }

            activeChannels.forEach(channel => {
                // Meta export is not available on the back-end...
                if (channel === PLATFORM_FACEBOOK) {
                    const metaExport = {
                        channel: 'meta',
                        url: `${FEEDS_BASE_URL}/${this.currentDealer.default_remote_filename}_automotive_catalog.csv`,
                    };
                    this.displayedExports.push(metaExport);
                }
                // all other channels
                this.catalogExports.forEach(exp => {
                    const exportName = exp.remote_file_type.name;
                    const currentChannel = exportName.substring(0, exportName.indexOf(' '));
                    if (channel === currentChannel.toLowerCase() ||
                        (channel == PLATFORM_MICROSOFT && currentChannel === 'Bing')) {
                        const catalogExport = {
                            channel,
                            name: exportName,
                            url: exp.url,
                        };
                        this.displayedExports.push(catalogExport);
                    }
                });
            });
        },
        close() {
            this.$emit('close');
        },
        onCopy() {
            this.$flash('Copied to clipboard!', 'green');
        },
    },
};
</script>

<style lang="scss" scoped>
.exports {
    padding: 55px 65px;
}

.exports-title {
    font-size: 20px;
    font-weight: 600;
    color: $blue-bg;
    margin-bottom: 10px;
}

.exports-desc {
    margin-bottom: 25px;
}

.exports-logo {
    width: 30px;
    height: auto;
    margin-right: 15px;
}

.exports-bold {
    font-weight: 600;
}

.exports-block {
    display: flex;
    align-items: center;
}

.exports-left {
    width: 30%;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.exports-right {
    width: 100%;
    display: flex;
    align-items: center;
}

.exports-link {
    word-break: break-all;
    width: 80%;
}

.exports-copy {
    padding-left: 15px;
}

.exports-header .exports-block {
    padding: 15px 37px;
}

.exports-body .exports-block {
    padding: 0 37px 5px 37px;
    height: 100px;

    &:nth-of-type(odd) {
        background-color: #F8F8F8;
        border-top: 1px solid #E0E0E0;
        border-bottom: 1px solid #E0E0E0;
    }
}

.no-inventory-notice {
    background-color: #F8CACA;
    border: 1px solid #E12020;
    padding: 12px 20px;
    margin-bottom: 15px;
    font-size: 13px;

    span {
        text-decoration: underline;
        cursor: pointer;
        color: $gray-dark;
    }
}

.marketplace-note {
    color: $orange-dark;
    padding: 60px 90px 0 90px;
    text-align: center;
}
</style>
